import { Result, Container, Header } from './ResultFilter.styles';
import { useSelector } from 'react-redux';
import { StudioSelectors } from '../../store/studio/studio.selectors';
import {
  getFilteredCheckIns,
  getFilteredMemberships,
  getResolvedCategories,
} from '../FilterModal/FilterModal.utils';
import { useIntl } from 'react-intl';
import { PageSelectors } from '../../store/page/page.selectors';

export const ResultFilter = () => {
  const intl = useIntl();
  const sidebarOpen = useSelector(PageSelectors.getSidebar);
  const inputInformation = useSelector(StudioSelectors.getInputInformation);
  const radius = useSelector(StudioSelectors.getRadiusFilterValue);
  const filter = useSelector(StudioSelectors.getFilter);
  const allCategories = useSelector(StudioSelectors.getCategories);
  const categories = getResolvedCategories(filter.categories, allCategories).join(', ');
  const search = inputInformation.map(item => item.name).join(', ');
  const filteredMemberships = getFilteredMemberships(filter);
  const filteredCheckIns = getFilteredCheckIns(filter, intl.formatMessage);
  const filename = inputInformation.find(item => item.fileId.length > 0)?.filename;

  return (
    <Container>
      <Header>
        {intl.formatMessage({
          id: 'result.filter.radius.header',
          defaultMessage: 'Radius',
        })}
        :
      </Header>
      <Result $isOpen={sidebarOpen}>{radius} km</Result>

      <Header>
        {intl.formatMessage({
          id: 'result.filter.upload_file.header',
          defaultMessage: 'Upload-File',
        })}
        :
      </Header>
      <Result $isOpen={sidebarOpen}>{filename ? filename : '-'}</Result>

      <Header>
        {intl.formatMessage({
          id: 'result.filter.location_search.header',
          defaultMessage: 'Location Search',
        })}
        :
      </Header>
      <Result $isOpen={sidebarOpen}>{search}</Result>

      <Header>
        {intl.formatMessage({
          id: 'result.filter.partner_categories.header',
          defaultMessage: 'Partner categories',
        })}
        :
      </Header>
      <Result $isOpen={sidebarOpen}>{categories ? categories : '-'}</Result>

      <Header>
        {intl.formatMessage({
          id: 'result.filter.membership.header',
          defaultMessage: 'Membership',
        })}
        :
      </Header>
      <Result $isOpen={sidebarOpen}>{filteredMemberships}</Result>

      <Header>
        {intl.formatMessage({
          id: 'result.filter.check_in_and_services.header',
          defaultMessage: 'Check-in/Services',
        })}
        :
      </Header>
      <Result $isOpen={sidebarOpen}>{filteredCheckIns ? filteredCheckIns : '-'}</Result>
    </Container>
  );
};
