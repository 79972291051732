/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_pW1s2Og2Z",
    "aws_user_pools_web_client_id": "2fnb3sf5vbb9q41so5jk40hn8g",
    "oauth": {
        "domain": "sales-explorer-dev.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "phone"
        ],
        "redirectSignIn": "https://localhost:3000/signin,https://vpe-sales.dev.hansefit.de/signin",
        "redirectSignOut": "https://localhost:3000/signout,https://vpe-sales.dev.hansefit.de/signout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
